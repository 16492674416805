import React, { useState, useMemo } from "react"
import { graphql } from "gatsby"

import Layout from "../../theme/layout"
import SEO from "../../components/header/seo"
import ShopPageContent from "../../components/shop-page/shop-page-content"

const CategoryPage = ({ pageContext, data, location }) => {
  // Get all products from query
  const childCategories = pageContext.child_categories || []
  const categoryProducts = data.allSeaProduct.nodes || []
  const categoryAttributes = pageContext.attributes || []

  return (
    <Layout pathContext={pageContext} location={location}>
      <SEO
        title={pageContext.title}
        location={location}
        yoast={pageContext.seo}
      />
      <ShopPageContent
        title={pageContext.title}
        categories={childCategories}
        products={categoryProducts}
        attributes={categoryAttributes}
      />
    </Layout>
  )
}

export default CategoryPage

// ===============
//      QUERY
// ===============
export const query = graphql`
  query ($database_id: Int!) {
    allSeaProduct(
      filter: {
        categories: { elemMatch: { database_id: { eq: $database_id } } }
      }
    ) {
      nodes {
        ...categoryPageProducts
      }
    }
  }
`
